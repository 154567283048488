<template>
  <div div id="index" ref="index">
    <Loading />
    <ul class="fixed top-1/2 right-0 z-20" style="transform: translateY(-50%);">
      <li
        class="w-3 h-3 block bg-white rounded-full m-4 cursor-pointer"
        :class="{ 'bg-primary': page == index }"
        @click.prevent="$scrollTo(`#${item.name}`)"
        v-for="(item, index) in items"
        :key="index"
      ></li>
    </ul>
    <component
      v-bind:is="item.component"
      v-for="(item, index) in items"
      :key="index"
      :id="item.name"
      class="min-h-screen"
    ></component>
  </div>
</template>

<script>
import Loading from "@/components/Theme/Loading";
import Index from "@/components/Inspiration/Index";
import One from "@/components/Inspiration/One";
import Two from "@/components/Inspiration/Two";
import Three from "@/components/Inspiration/Three";
import Four from "@/components/Inspiration/Four";
import Five from "@/components/Inspiration/Five";
import Six from "@/components/Inspiration/Six";
import Seven from "@/components/Inspiration/Seven";
import Eight from "@/components/Inspiration/Eight";
import Nine from "@/components/Inspiration/Nine";
import End from "@/components/Inspiration/End";

export default {
  name: "Inspiration",
  data() {
    return {
      items: [
        { component: "Index", name: "inspiration" },
        { component: "One", name: "one" },
        { component: "Two", name: "two" },
        { component: "Three", name: "three" },
        { component: "Four", name: "four" },
        { component: "Five", name: "five" },
        { component: "Six", name: "six" },
        { component: "Seven", name: "seven" },
        { component: "Eight", name: "eight" },
        { component: "Nine", name: "nine" },
        { component: "End", name: "end" }
      ],
      page: 0,
      h: 0
    };
  },
  components: {
    Loading,
    Index,
    One,
    Two,
    Three,
    Four,
    Five,
    Six,
    Seven,
    Eight,
    Nine,
    End
  },
  mounted() {
    const vm = this;
    vm.windowSize();
    window.addEventListener("resize", vm.windowSize);
    window.addEventListener("scroll", vm.onScroll);
  },
  methods: {
    windowSize() {
      this.h = window.innerHeight;
    },
    onScroll() {
      for (let i = 0; i < this.items.length; i++) {
        if (
          window.scrollY >= this.h * i - 90 &&
          window.scrollY < this.h * (i + 1) - 90
        ) {
          this.page = i;
        }
      }
      return false;
    }
  },
  beforeDestroy() {
    const vm = this;
    window.removeEventListener("resize", vm.windowSize);
    window.removeEventListener("scroll", vm.onScroll);
  }
};
</script>
