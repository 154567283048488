<template>
  <section
    class="w-full bg-cover bg-center relative"
    :style="
      `background-image: url('${require('@/assets/img/inspiration/7.jpg')} ')`
    "
  >
    <div class="absolute top-0 w-full">
      <img src="@/assets/img/logo.svg" class="lg:h-32 h-24 mx-auto" alt="Goodway" />
    </div>
    <div
      class="w-full absolute bottom-0 left-0 bg-thirdry lg:h-48 flex items-center justify-center"
    >
      <div class="px-5">
        <h4
          class="md:py-6 lg:text-3xl text-xl text-gray-200 font-sans text-center font-thin"
          v-animate-onscroll="'animate__animated animate__fadeIn animate__slow'"
        >
          Odkryj stan, który sprawi, że
          <span class="font-extrabold">Twoje życie zaświeci na nowo</span>
          <span
            class="lg:h-10 h-6 lg:pl-6 pl-4 bg-cover bg-center"
            :style="
            `background-image: url('${require('@/assets/img/dot.svg')} ');`
          "
          ></span>
        </h4>
      </div>
    </div>
    <div
      class="w-full absolute bottom-0 left-0 bg-right-bottom bg-cover md:hidden"
      :style="
        `background-image: url('${require('@/assets/img/ecg.png')} '); height: 25rem;`
      "
    ></div>
  </section>
</template>

<script>
export default {
  name: "InspirationOne"
};
</script>
