<template>
  <section class="w-full bg-thirdry flex flex-col items-center lg:justify-center">
    <div
      class="w-full py-10 text-gray-200 flex flex-col justify-center items-center my-5"
      v-animate-onscroll="'animate__animated animate__fadeIn animate__slow'"
    >
      <div class="container px-10 text-center lg:text-2xl text-lg pb-8" v-html="text"></div>
      <router-link
        to="/miejsce-odkrycia"
        v-animate-onscroll="'animate__animated animate__fadeIn animate__slow'"
      >
        <button
          class="mx-auto pulse rounded-full w-24 h-24 uppercase text-gray-200 bg-secondary font-sans text-sm tracking-wider"
        >Odkryj jeszcze więcej</button>
      </router-link>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "End",
  data() {
    return {
      text: ""
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      await axios.get("/api/app/5").then(response => {
        this.text = response.data.text;
      });
    }
  }
};
</script>
